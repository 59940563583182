import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="about-area page-section scroll-to-page" id="about">
        <div className="custom-container">
            <div className="about-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="lar la-user"></i> About
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Unlocking possibilities through<br/> innovative code</h1>
                </div>
                <p className="scroll-animation" data-aos='fade-up'>I have been deeply immersed in the world of development for the past few years, specializing in Rust server and plugin development. Additionally, I have gained valuable experience in creating Harmony mods. With a strong focus on crafting immersive experiences, I have collaborated with notable content creators and companies. Notably, I developed a 10x modded Rust server that reached a peak of 600 players. My straightforward and results-oriented approach drives me to deliver high-quality solutions. I find motivation in helping others and making a positive impact in the gaming community. Proficient in C# and JavaScript, and experienced in creating Harmony mods, I am always eager to explore new technologies and expand my repertoire. From my early days to the present, I thrive on challenges and strive to deliver exceptional solutions.</p>
            </div>
        </div>
    </section>
  )
}
