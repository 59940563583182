import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Skills() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
<section className="skills-area page-section scroll-to-page" id="skills">
    <div className="custom-container">
        <div className="skills-content content-width">
            <div className="section-header">
                <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                    <i className="las la-shapes"></i> my skills
                </h4>
                <h1 className="scroll-animation" data-aos='fade-up'>My <span>Skills</span></h1>
            </div>

            <div className="row skills text-center">
                <div className="col-md-4 scroll-animation" data-aos='fade-right'>
                    <div className="skill">
                        <div className="skill-inner">
                            <h1 className="percent">C#</h1>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 scroll-animation" data-aos='fade-up'>
                    <div className="skill">
                        <div className="skill-inner">
                            <h1 className="percent">JavaScript</h1>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 scroll-animation" data-aos='fade-left'>
                    <div className="skill">
                        <div className="skill-inner">
                            <h1 className="percent">Harmony</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
