import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Clientlogos() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section>
        <div className="custom-container">
            <div className="services-content content-width">
                <div class="clients-logos">
                    <h4 class="scroll-animation" data-aos='fade-up'>worked with countless servers and people</h4>
                    <div class="row align-items-center">
                        <div class="col-md-3 scroll-animation" data-aos='fade-right'>
                            <img src="https://files.tryhard.cloud/s/r9XsLpoZgjf6NRq/preview" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-up'>
                            <img src="https://files.tryhard.cloud/s/crqpStx4LS8i94R/preview" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation"  data-aos='fade-down'>
                            <img src="https://files.tryhard.cloud/s/efKDF4BboByaryA/preview" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-left'>
                            <img src="https://files.tryhard.cloud/s/3jBfDeCS8mo527S/preview" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-right'>
                            <img src="https://files.tryhard.cloud/s/rnwNMbPaNe7HBWs/preview" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-up'>
                            <img src="https://files.tryhard.cloud/s/FgES6tGbcQi3NYf/preview" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation"  data-aos='fade-down'>
                            <img src="https://files.tryhard.cloud/s/Jgik8A526JEyj69/preview" alt="Client"/>
                        </div>
                        <div class="col-md-3 scroll-animation" data-aos='fade-left'>
                            <img src="https://files.tryhard.cloud/s/yPm8six3cBrHS9B/preview" alt="Client"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}


