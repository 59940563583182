import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i> Resume
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'><span>Experience</span></h1>
                </div>

                <div className="resume-timeline">
                <div className="item scroll-animation" data-aos='fade-right'>
    <span className="date">2021 - 2023</span>
    <h2>Owner & Developer</h2>
    <p>Exotic Rust</p>
</div>
<div className="item scroll-animation" data-aos='fade-right'>
    <span className="date">2023 - 2023</span>
    <h2>Senior Developer</h2>
    <p>Rustopia Official</p>
</div>
<div className="item scroll-animation" data-aos='fade-right'>
    <span className="date">2023 - 2023</span>
    <h2>Senior Developer</h2>
    <p>Vortex.gg</p>
</div>
<div className="item scroll-animation" data-aos='fade-right'>
    <span className="date">2021 - 2022</span>
    <h2>Developer</h2>
    <p>Rustinty</p>
</div>
<div className="item scroll-animation" data-aos='fade-left'>
    <span className="date">2021 - 2022</span>
    <h2>Lead Developer</h2>
    <p>Estonia Rust</p>
</div>
<div className="item scroll-animation" data-aos='fade-right'>
    <span className="date">2021 - 2021</span>
    <h2>Owner & Developer</h2>
    <p>GS Rust Events</p>
</div>
<div className="item scroll-animation" data-aos='fade-right'>
    <span className="date">2020 - 2021</span>
    <h2>Owner & Developer</h2>
    <p>Estonia Rust</p>
</div>
                </div>

            </div>
        </div>
    </section>
  )
}
