import React, { useRef, useEffect } from "react";
import OwlCaraousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'owl.carousel/dist/assets/owl.theme.default.min.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Testimonials() {
    
    const carouselRef = useRef(null);

    useEffect(() => {
        AOS.init({duration:1000})
    }, [])

    const handlePrevClick = () => {
        carouselRef.current.prev();
    }

    const handleNextClick = () => {
        carouselRef.current.next();
    }
  
  return (
    <section className="testimonial-area page-section scroll-to-page" id="testimonial">
            {/* <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script> */}

            {/* <script src="https://code.jquery.com/jquery-3.6.4.slim.min.js" integrity="sha256-a2yjHM4jnF9f54xUQakjZGaqYs/V1CYvWpoqZzC2/Bw=" crossorigin="anonymous"></script> */}


            <div className="custom-container">
                <div className="testimonial-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="lar la-comment"></i> testimonial
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Trusted by <span>Many</span></h1>
                    </div>    
                    <div className="testimonial-slider-wrap scroll-animation" data-aos='fade-up'>

                        <OwlCaraousel className="owl-carousel testimonial-slider owl-theme" smartSpeed="450" items="1" dots={false} ref={carouselRef}>

                        <div className="testimonial-item">
                                <div className="testimonial-item-inner">
                                    <div className="author d-flex align-items-center">
                                        <img src="https://files.tryhard.cloud/s/JpzXK7JCRPRd8nP/preview" alt="portfolio"/>
                                        <div className="right">
                                            <h3>FancyOrb</h3>
                                            <p className="designation">Youtuber with <span>750k+ followers</span></p>
                                        </div>
                                    </div>
                                    <p>“Plugin is a W! Really funny, will be a great video!”</p>
                                </div>
                            </div>
                        </OwlCaraousel>
                        <div className="testimonial-footer-nav">

                        </div>
                    </div>
                </div>
            </div>
    </section>
  )
}