import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="services-area page-section scroll-to-page" id="services">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-stream"></i> Services
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                </div>

                <div className="services-items">
    <div className="service-item scroll-animation" data-aos='fade-up'>
        <i className="las la-code"></i>
        <h2>Plugin Development</h2>
        <p>I specialize in crafting custom C# plugins for Rust servers, enhancing gameplay and expanding possibilities.</p>
        <span className="projects">500+ Projects</span> 
    </div>
    <div className="service-item scroll-animation" data-aos='fade-up'>
        <i className="las la-bezier-curve"></i>
        <h2>Harmony Mods</h2>
        <p>Utilizing my expertise in Harmony, I create mods that enhance and extend the functionalities of games with elegance and precision.</p>
        <span className="projects">100+ Projects</span> 
    </div>
</div>
            </div>
        </div>
    </section>
  )
}
